import {getClientId, getGoogleToken, passGoogleAuthCode} from '@/api/auth';

export const GoogleAuth = {
    async getToken() {
        const response = (await getGoogleToken()).data
        if (response.token) {
            return response.token
        }
        else if (response.needAuthInGoogle) {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            if (!code) {
                await this.oauth2SignIn()
            } else {
                await passGoogleAuthCode(code)
                return await this.getToken()
            }
        }
    },
    async checkCode()
    {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            await passGoogleAuthCode(code)
            window.location.reload()
        }
    },
    async oauth2SignIn() {
        // Google's OAuth 2.0 endpoint for requesting an access token
        const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

        // Create element to open OAuth 2.0 endpoint in new window.
        const form = document.createElement('form');
        form.setAttribute('method', 'GET'); // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint);

        const clientId = (await getClientId()).data?.client_id

        // Parameters to pass to OAuth 2.0 endpoint.
        const params = {
            'client_id': clientId,
            'redirect_uri': document.location.origin + '/home',
            'scope': [
                "openid",
                "profile",
                "email",
                "https://www.googleapis.com/auth/drive.readonly",
                "https://www.googleapis.com/auth/drive.metadata",
            ].join(' '),
            // 'state': 'auth_request',
            'include_granted_scopes': 'true',
            'access_type': 'offline',
            'prompt': 'consent',
            'response_type': 'code',
        };

        let input
        // Add form parameters as hidden input values.
        for (const p in params) {
            input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', p);
            input.setAttribute('value', params[p]);
            form.appendChild(input);
        }

        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form);
        form.submit();
    }
}