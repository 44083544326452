import api from "@/utils/http/api";

export const getTags = async () => api.get('tags')

export const saveTag = async (newTag) => api.post('tags', newTag)

export const deleteTag = async (tagId) => api.delete(`tags/${tagId}`)

export const updateTag = async (tagId, tagName) => api.patch(`tags/${tagId}`, {
    name: tagName
})

export const applyToAllInDir = async (dirId, tags) => api.post('tags/apply-to-all-in-dir', {dirId, tags})