import request from '@/utils/http/api'

export const getUser = () => request.get('permissions/users')

export const getRoles = () => request.get('permissions/roles')

export const deleteRoles = (id) => request.post(`permissions/users/${id}/delete-roles`)

export const updateUser = (id, field) =>
  request.post(`permissions/users/${id}/assign-roles`, field)
