<template>
  <div>
    <vue3-progress-bar></vue3-progress-bar>
    <router-view/>
  </div>
</template>

<script lang="ts">



export default {
  name: 'App'
}
</script>
