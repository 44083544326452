import Keycloak from 'keycloak-js'

const initOptions = {
  url: process.env.VUE_APP_OIDC_URL,
  realm: process.env.VUE_APP_OIDC_REALM,
  clientId: process.env.VUE_APP_OIDC_CLIENT_ID,
  onLoad: 'login-required'
}

// @ts-ignore
const _keycloak = new Keycloak(initOptions)

const Plugin = {
  install: (Vue) => {
    Vue.$keycloak = _keycloak;
    Object.defineProperties(Vue.config.globalProperties, {
      $keycloak: {
        get() {
          return _keycloak;
        },
      },
    });
  }
}

export default Plugin
