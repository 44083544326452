import Auth from '@/store/modules/auth';
import Loader from '@/store/modules/loader';
import Permissions from "@/store/modules/permissions";

import {createStore} from 'vuex'
import {getFiles, getTagsFromFile} from '@/utils/google/drive';
import {getUserProfile} from '@/api/google/google';
import {filesUpdateMetadata, getAbout, getDrivesList, getFile, propertiesDelete, propertiesInsert} from '@/api/google/drive';
import {FilesRequest} from '@/utils/google/drive';
import {applyToAllInDir, saveTag} from '@/api/tags';
import Tags from '@/store/modules/tags';
import {fileTypeFilterData, FileTypes, TYPE_FOLDER} from '@/types/drive';
import {FileViewType} from '@/types/app';
import {listBackgroundProcess} from '@/api/backgroundProcess';
import {forgetAccessToGoogle} from '@/api/auth';
import {GoogleAuth} from '@/utils/google/auth';

export default createStore({
    state: {
        selectedFile: {
            id: null,
            name: null,
            mimeType: null,
            webContentLink: null,
            exportLinks: null,
            webViewLink: null,
            iconLink: null,
            tags: [],
            parents: [],
            folderColorRgb: null,
            originalName: null,
            hasThumbnail: null
        },
        driveId: null,
        applyTagsToAllInOpenedDir: false,
        renameAndReplaceTags: false,
        currentDirId: null,
        isSearch: false,
        searchTags: [],
        searchMimeTypes: [],
        drives: [],
        files: [],
        nextPageToken: null,
        googleUser: null,
        breadCrumbs: [],
        lastFilesRequest: null,
        backgroundProcess: [],
        backgroundProcessInterval: null,
        folderColorPalette: [],
        fileViewType: FileViewType.table,
        dirs: {},
        isInit: false
    },
    mutations: {
        set(state, payload) {
            state[payload.k] = payload.v
        },
        setSelectedTags(state, payload) {
            state.selectedFile.tags = payload
        }
    },
    getters: {
        selectedTagsIds(state) {
            return state.selectedFile.tags.map(tag => Number(tag.id))
        },
        tagsIds(state) {
            return state['Tags/tags'].map(tag => Number(tag.id))
        },
        tagsNames(state) {
            return state['Tags/tags'].map(tag => tag.name)
        }
    },
    actions: {
        async reloadFiles({dispatch, state}) {
            await dispatch('getFiles', state.lastFilesRequest)
        },
        async getFiles({commit}, filesRequest: FilesRequest) {
            commit('set', {k: 'lastFilesRequest', v: filesRequest})
            const filesResponse = await getFiles(filesRequest)
            commit('set', {k: 'files', v: filesResponse.files})
            commit('set', {k: 'nextPageToken', v: filesResponse.nextPageToken})
        },
        async loadMoreFiles({state, commit}) {
            const filesResponse = await getFiles(state.lastFilesRequest.setPageToken(state.nextPageToken))
            commit('set', {k: 'files', v: [...state.files, ...filesResponse.files]})
            commit('set', {k: 'nextPageToken', v: filesResponse.nextPageToken})
        },
        async initBreadCrumbs({commit, state}) {
            commit('set', {
                k: 'breadCrumbs', v:
                    [{
                        name: state.drives[0]?.name,
                        id: state.drives[0]?.id,
                        file: null
                    }]
            })
        },
        async toggleStared({dispatch, state}, file) {
            const {id, starred} = file
            await filesUpdateMetadata(id, {
                starred: !starred
            })
            dispatch('getFiles', state.lastFilesRequest)
        },
        async openRootDir({commit, dispatch}) {
            commit('set', {k: 'isSearch', v: false})
            dispatch('getFiles', (new FilesRequest()).whereInParent('root'))
            commit('set', {k: 'currentDirId', v: 'root'})
            dispatch('initBreadCrumbs')
        },
        async openDir({commit, state, dispatch}, dir) {
            const {id = null, name, mimeType} = dir

            commit('set', {k: 'isSearch', v: false})
            commit('set', {k: 'searchTags', v: []})

            if (mimeType === fileTypeFilterData[TYPE_FOLDER]) {
                dispatch('getFiles', (new FilesRequest()).whereTags(state.searchTags).whereInParent(`${id}`))
                commit('set', {k: 'currentDirId', v: id})

                let currentFileInBreadCrumb = false
                commit('set', {
                    k: 'breadCrumbs', v: [
                        ...state.breadCrumbs.filter(item => {
                            if (currentFileInBreadCrumb) {
                                return false
                            }
                            if (item.id === id) {
                                currentFileInBreadCrumb = true
                                return false
                            }
                            return true
                        }), {name: name, id: id, file: dir}
                    ]
                })
            }
        },
        async setSelected({commit, state, rootState, getters}, file) {
            const {
                id = null, name, properties, mimeType, parents, iconLink, webContentLink, exportLinks, webViewLink, folderColorRgb,
                hasThumbnail, thumbnailLink
            } = file
            if (!id) return
            commit('set', {
                k: 'selectedFile', v: {
                    id, name, parents, iconLink, mimeType, webContentLink, exportLinks, webViewLink, folderColorRgb,
                    hasThumbnail, thumbnailLink, properties,
                    originalName: name,
                    tags: properties ? Object.keys(properties).map(prop => Number(prop)).map(tagId =>
                        rootState['Tags'].tags.find(tag => Number(tag.id) == Number(tagId))
                    ) : []
                }
            })
        },
        clearSelected({commit}) {
            commit('set', {
                k: 'selectedFile', v: {
                    id: null,
                    mimeType: null,
                    name: null,
                    tags: [],
                    parents: [],
                    folderColorRgb: null,
                    originalName: null
                }
            })
        },
        async saveSelected({state, dispatch, commit, getters}) {
            const file = (await getFile(state.selectedFile.id)).data

            const selectedTags = []
            for (let selectedTag of state.selectedFile.tags) {
                if (!selectedTag?.id) {
                    await saveTag({name: selectedTag})
                    await dispatch('Tags/getTags')
                    selectedTag = state['Tags'].tags.find(tag => tag.name == selectedTag)
                }
                selectedTags.push(selectedTag)
            }

            await commit('setSelectedTags', selectedTags)

            const existTagsIds = getTagsFromFile(file);
            if (existTagsIds.length) {
                existTagsIds.forEach(async id => {
                    if (!getters.selectedTagsIds.includes(id)) {
                        await propertiesDelete(state.selectedFile.id, id)
                    }
                })
            }

            for (const selectedTagId of getters.selectedTagsIds) {
                if (!existTagsIds.includes(Number(selectedTagId))) {
                    await propertiesInsert(state.selectedFile.id, {
                        visibility: 'PUBLIC',
                        key: selectedTagId,
                        value: true
                    });
                }
            }
            if (state.selectedFile.name !== file.name
                || (file.mimeType === FileTypes.dir && state.selectedFile.folderColorRgb !== file.folderColorRgb)
            ) {
                await filesUpdateMetadata(state.selectedFile.id, {
                    name: state.selectedFile.name,
                    folderColorRgb: state.selectedFile.folderColorRgb
                })
            }
            if (state.applyTagsToAllInOpenedDir) {
                applyToAllInDir(state.selectedFile.id, getters.selectedTagsIds)
            }

            await dispatch('reloadFiles')

            const selectedFile = (await getFile(state.selectedFile.id)).data
            await dispatch('setSelected', selectedFile)
        },
        async search({state, commit, dispatch}) {
            if (!state.searchTags.length && !state.searchMimeTypes.length) {
                return await dispatch('openRootDir')
            }
            const request = (new FilesRequest())
                .whereTags(state.searchTags)
                .whereInFileType(state.searchMimeTypes);
            commit('set', {k: 'currentDirId', v: null})
            commit('set', {k: 'isSearch', v: true})
            await dispatch('getFiles', request)
            await dispatch('initBreadCrumbs')

        },
        async getColorPalette({commit}) {
            const about = await getAbout()
            commit('set', {k: 'folderColorPalette', v: about.data.folderColorPalette})
        },
        async setFilesViewType({commit}, type) {
            await commit('set', {k: 'fileViewType', v: type})
        },
        async getBackgroundProcess({commit, state}) {
            const p = await listBackgroundProcess()
            p.data.map(process => process.meta = JSON.parse(process.meta))
            p.data.forEach(async process => {
                const dir = state.dirs[process.meta.dirId]
                if (!dir) {
                    const dir = await getFile(process.meta.dirId)
                    const dirs = state.dirs
                    dirs[process.meta.dirId] = dir.data.name
                    await commit('set', {k: 'dirs', v: dirs})
                }
            })
            commit('set', {k: 'backgroundProcess', v: p.data})
        },
        async forgetAccessGoogle({state}) {
            await forgetAccessToGoogle(state.googleUser.email)
            GoogleAuth.oauth2SignIn()
        },
        async init({commit, state, dispatch}) {
            if (!state.isInit) {
                const drives = await getDrivesList();
                await commit('set', {k: 'drives', v: drives.data.items})
                await commit('set', {k: 'driveId', v: drives.data.items[0].id})

                await dispatch('openRootDir', drives.data.items[0].id)

                const gUser = await getUserProfile()
                await commit('set', {k: 'googleUser', v: gUser.data})

                if (!state.backgroundProcessInterval) {
                    const interval = setInterval(() => dispatch('getBackgroundProcess'), 5000)
                    commit('set', {k: 'backgroundProcessInterval', v: interval})
                }

                dispatch('Tags/getTags')
                dispatch('getColorPalette')
                dispatch('initBreadCrumbs')

                commit('set', {k:'isInit', v: true})
            }
        }
    },
    modules: {
        Auth, Loader, Tags, Permissions
    }
})
