import {createApp} from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import {Vue3ProgressPlugin} from '@marcoschulte/vue3-progress'
import authentication from '@/plugins/authentication'
import {GoogleAuth} from '@/utils/google/auth';

import 'bootstrap/dist/css/bootstrap.css'
import 'element-plus/dist/index.css'
import '@/assets/style/main.scss'

interface App {
    $keycloak?: any;
    [key: string]: any;
}

export const app: App = createApp(App)

app.use(ElementPlus)
app.use(store)
app.use(router)
app.use(authentication)
app.use(Vue3ProgressPlugin)

if (process.env.VUE_APP_DEV_MODE === 'true' && process.env.VUE_APP_MOCK_KEYCLOAK_USER === 'true') {
    store.dispatch('Auth/setUser', { email: 'sergey.s@bimiboo.com' })
    store.dispatch('Auth/setAdmin', true)

    GoogleAuth.getToken().then(token => {
        localStorage.setItem('token', token)
        app.mount('#app')
    })

} else {
    app.$keycloak.init({onLoad: 'login-required'}).then(async (auth) => {
        if (!auth) {
            window.location.reload()
        } else {
            await app.$keycloak
                .loadUserInfo()
                .then((res) => store.dispatch('Auth/setUser', res))
            await store.dispatch(
                'Auth/setAdmin',
                app.$keycloak.hasResourceRole('admin', process.env.VUE_APP_OIDC_CLIENT_ID)
            )

            GoogleAuth.getToken().then(token => {
                localStorage.setItem('token', token)
                app.mount('#app')
            })
        }

        setInterval(() => {
            app.$keycloak.updateToken(70)
        }, 6000)
    })
}
