import { AuthData, AuthModuleState } from '@/types/auth'

const Auth = {
  namespaced: true,
  state: () => ({
    isAdmin: false,
    data: {} as AuthData
  } as AuthModuleState),
  mutations: {
    SET_ADMIN (state, response) {
      state.isAdmin = response
    },
    SET_USER (state, response) {
      state.data = response
    }
  },
  actions: {
    setAdmin ({ commit }, payload) {
      commit('SET_ADMIN', payload)
    },
    setUser ({ commit }, payload) {
      commit('SET_USER', payload)
    }
  }
}

export default Auth
