const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: 'File Manager'
    },
    component: () => import('@/components/pages/FilesViewPage.vue')
  },
  {
    path: '/tags',
    name: 'tags',
    meta: {
      title: 'Tags'
    },
    component: () => import('@/components/pages/TagsPage.vue')
  },
  {
    path: '/permissions',
    name: 'permissions',
    meta: {
      isAdmin: true,
      title: 'Permissions'
    },
    component: () => import('@/components/pages/PermissionsPage.vue')
  },
]

export default routes
