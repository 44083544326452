import {
  getUser, getRoles, deleteRoles, updateUser
} from '@/api/permissions'
import { PermissionsModuleState } from '@/types/permissions'

const Permissions = {
  namespaced: true,
  state: () => ({
    users: [],
    roles: [],
    loading: false
  } as PermissionsModuleState),
  mutations: {
    SET_LIST (state, users = []) {
      state.users = users
    },
    SET_ROLES (state, roles = []) {
      state.roles = roles
    },
    SET_LOADING (state, value) {
      state.loading = value
    },
    DELETE_ITEM_FROM_LIST (state, id) {
      state.users = state.users.filter((item) => item.id !== id)
    },
    UPDATE_ITEM_IN_LIST (state, data) {
      state.users = state.users.map((item) => {
        if (item.id === data.id) {
          return { ...data }
        }
        return item
      })
    }
  },
  actions: {
    async getUsers ({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await getUser()
        commit('SET_LIST', data.data || [])
      } catch (e) {
        console.error(e)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getRoles ({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await getRoles()
        commit('SET_ROLES', data.data || [])
      } catch (e) {
        console.error(e)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deleteRoles ({ commit }, payload) {
      try {
        commit('SET_LOADING', true)
        const res = await deleteRoles(payload)
        if (res.data.code === 200) {
          commit('UPDATE_ITEM_IN_LIST', res.data.data)
        }
        // if (res.code === 200) {
        //   commit('UPDATE_ITEM_IN_LIST', res.data)
        //   Vue.$toast.success('User deleted successfully', {
        //     duration: 0
        //   })
        // } else {
        //   Vue.$toast.error(res.message, {
        //     duration: 0
        //   })
        // }
      } catch (e) {
        console.error(e)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async updateUser ({ commit }, payload) {
      try {
        commit('SET_LOADING', true)
        const res = await updateUser(payload.id, payload.field)
        commit('UPDATE_ITEM_IN_LIST', res.data.data)
        // if (res.code === 200) {
        //   Vue.$toast.success('Operation was successful', {
        //     duration: 0
        //   })
        // } else {
        //   if (res.code === 422) {
        //     Vue.$toast.error(res.data, {
        //       duration: 0
        //     })
        //   } else {
        //     Vue.$toast.error('Something went wrong', {
        //       duration: 0
        //     })
        //   }
        // }
      } catch (e) {
        console.error(e)
      } finally {
        commit('SET_LOADING', false)
      }
    }
  }
}

export default Permissions
