import axios from 'axios'
import {useProgress} from '@marcoschulte/vue3-progress'
// import {GoogleAuth} from "@/utils/google/auth";

const progresses = []

const gapi = axios.create({
    baseURL: 'https://www.googleapis.com',
    timeout: 60000,
    headers: {}
})

gapi.interceptors.request.use(
    function (config) {
        const token =  localStorage.getItem('token')
        config.headers.Authorization = `Bearer ${token}`

        progresses.push(useProgress().start())
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)
gapi.interceptors.response.use(resp => {
    progresses.pop()?.finish()
    return resp
}, (error) => {
    progresses.pop()?.finish()
    // window.location.reload()
    console.log(error)
    return Promise.reject(error)
})

export default gapi
