import { LoaderModuleState } from '@/types/loader'

const Loader = {
  namespaced: true,
  state: () => ({
    isLoading: false
  } as LoaderModuleState),
  mutations: {
    UPDATE_LOADER (state, value) {
      state.isLoading = value
    }
  },
  actions: {
    setLoader ({ commit }, value) {
      commit('UPDATE_LOADER', value)
    }
  }
}

export default Loader
