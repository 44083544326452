import axios from 'axios'
import { app } from '@/main'
import {useProgress} from '@marcoschulte/vue3-progress'

const progresses = []

const api = axios.create({
    baseURL: '/api/',
    timeout: 60000,
    headers: {}
})

api.interceptors.request.use(
    function (config) {
        const token = app.$keycloak.token
        config.headers.Authorization = `Bearer ${token}`

        const progress = useProgress()
        if (progress) {
            progresses.push(progress.start())
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)
api.interceptors.response.use(resp => {
    progresses.pop()?.finish()
    return resp
}, (error) => {
    progresses.pop()?.finish()
    return Promise.reject(error)
})

export default api
