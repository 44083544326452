export type FileProperty = {
    key: string,
    value: string
}

export type File = {
    "driveId"?: string
    'properties'?: Array<FileProperty>
    kind?: string
    mimeType?: string
    id: bigint
    name: string
    createdTime?:string
    hasThumbnail?:boolean
    iconLink?:string
    modifiedTime?:string
    size?:string
    starred?:boolean
    trashed?:boolean
}

export const FileTypes = {
    dir: 'application/vnd.google-apps.folder',
    zip: 'application/zip',
    photo: 'application/vnd.google-apps.photo',
    video: 'application/vnd.google-apps.video',
    doc: 'application/vnd.google-apps.document',
    sheet: 'application/vnd.google-apps.spreadsheet'
}

export const TYPE_FOLDER = 'Folder'
export const TYPE_IMAGES = 'Images'
export const TYPE_VIDEOS = 'Videos'
export const TYPE_DOCS = 'Docs'
export const TYPE_SHEETS = 'Sheets'

export const fileTypeFilterData = {}

fileTypeFilterData[TYPE_FOLDER] = FileTypes.dir
fileTypeFilterData[TYPE_IMAGES] = FileTypes.photo
fileTypeFilterData[TYPE_VIDEOS] = FileTypes.video
fileTypeFilterData[TYPE_DOCS] = FileTypes.doc
fileTypeFilterData[TYPE_SHEETS] = FileTypes.sheet
