import gapi from "@/utils/http/gapi";

export type FileProperty = {
    "selfLink"?: string,
    "value"?: string | boolean,
    "visibility"?: string,
    "kind"?: string,
    "etag"?: string,
    "key"?: string
};
export const propertiesInsert = async (fileId, property: FileProperty) => {
    console.log(property)
    await gapi.post(`drive/v2/files/${fileId}/properties`, property, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}


export const propertiesDelete = async (fileId: string, propertyKey) =>
    await gapi.delete(`drive/v2/files/${fileId}/properties/${propertyKey}?visibility=PUBLIC`)

export type FileListQueryParams = {
    'q'?: string,
    'pageSize'?: bigint
    fields?: string
}
export const getFilesList = async (params: FileListQueryParams = {}) =>
    await gapi.get('drive/v3/files', {
        params: params
    });

export const getDrivesList = async () =>
    await gapi.get('drive/v2/drives')

export const getFile = async (fileId: string) =>
    await gapi.get(`drive/v3/files/${fileId}?fields=*`)

export const filesUpdateMetadata = async (fileId, fileParams) =>
    await gapi.patch(`drive/v3/files/${fileId}`, fileParams)

export const getAbout = async () => gapi.get('drive/v2/about')