import {File, fileTypeFilterData, TYPE_DOCS, TYPE_IMAGES, TYPE_SHEETS, TYPE_VIDEOS} from '@/types/drive';
import {getFilesList, propertiesInsert} from '@/api/google/drive';
export class FilesRequest {

    public params;
    protected tags = []
    protected parents = []
    protected fileTypes = []

    constructor(params = {}) {
        this.params = {
            fields: 'files(' +
                'id,name,description,starred,createdTime,modifiedTime,size,' +
                'properties,driveId,modifiedTime' +
                ',hasThumbnail,thumbnailLink,' +
                'iconLink,mimeType,parents,folderColorRgb,' +
                'webContentLink,exportLinks,webViewLink' +
                ',trashed,createdTime,modifiedTime' +
                '),nextPageToken',
            orderBy: 'folder,modifiedTime desc,name',
            pageSize: 50,
            pageToken: null,
            q: ''
        };
        if (params) {
            this.params = {...this.params, ...params}
        }
    }
    public setPageToken(token) {
        this.params.pageToken = token
        return this
    }
    public whereTags(tags) {
        this.tags = tags
        return this
    }
    public whereInParents(query) {
        this.parents.push(query)
        return this
    }
    public whereInParent(query) {
        this.parents = [query]
        return this
    }
    public whereInFileType(types) {
        this.fileTypes = types
        return this
    }
    getParams() {
        let q = ''
        if (this.tags?.length) {
            q = '(';
            this.tags.forEach((tag, i) => {
                if (i) q += 'and '
                q += `properties has { key='${tag}' and value="true" } `
            })
            q += ') ';
        }
        if (this.parents?.length) {
            if (q.length) q += 'and '
            q += '('
            this.parents.forEach((v, i) => {
                if (i) q += ' or '
                q += `'${v}' in parents`
            })
            q += ') '
        }
        if (this.fileTypes?.length) {
            if (q.length) q += 'and '
            q += '('
            this.fileTypes.forEach((type, i) => {
                if (i) q += ' or '
                q += `(mimeType = '${fileTypeFilterData[type]}'`
                if (type === TYPE_IMAGES) {
                    q += ` or mimeType contains 'image'`
                }
                if (type === TYPE_VIDEOS) {
                    q += ` or mimeType contains 'video'`
                }
                if (type === TYPE_DOCS) {
                    q += ` or mimeType contains 'document'`
                }
                if (type === TYPE_SHEETS) {
                    q += ` or mimeType contains 'sheet'`
                }
                q += ') '
            })
            q += ') '
        }
        q += ' and trashed=false'
        this.params.q = q
        return this.params
    }
}



export const getFiles = async(request: FilesRequest = new FilesRequest()) => {
    const params = request.getParams();
    const resp = await getFilesList(params)
    return resp.data
}

export const addTagToFile = async (fileId: string, tag: string) => {
    await propertiesInsert(fileId, {
        key: tag,
        value: true,
        visibility: "PUBLIC"
    })
}

export const getTagsFromFile = (file: File) => {
    console.log(file)
    return file.properties
        ? Object.keys(file.properties).map(prop => Number(prop))
        : []
}
