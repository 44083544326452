import {deleteTag, getTags, updateTag} from '@/api/tags';

const Tags = {
  namespaced: true,
  state: () => ({
    tags: []
  }),
  mutations: {
    set(state, payload) {
      state[payload.k] = payload.v
    },
  },
  actions: {
    async getTags({commit}) {
      const tags = await getTags()
      await commit('set', {k: 'tags', v: tags.data})
    },
    async updateTag({dispatch}, tag) {
      await updateTag(tag.id, tag.name)
      await dispatch('getTags')
    },
    async deleteTag({dispatch}, tagId) {
      await deleteTag(tagId)
      await dispatch('getTags')
    },
  }
}

export default Tags
